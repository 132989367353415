import axios from "axios"

let instance = null
const API_URL = 'https://gr37gkl41h.execute-api.us-east-2.amazonaws.com/default/hostmanMongoOps'

const get = async (url, params) => {
    return await getInstance().get(url, { params: params ?? null })
        .then(response => response.data)
        .catch(error => handleError(error))
}

const handleError = (error) => {
    console.error(error)
    throw new Error('Error fetching the data')
}

const getInstance = () => {
    if (!instance) {
        instance = axios.create({
            baseURL: API_URL,
            timeout: 20000
        });
    }
    return instance
}

export default {
    get
}