<template>
    <div id="page-top">
        <div id="wrapper">

        <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div class="sidebar-brand-icon rotate-n-15">
                    <i class="fas fa-laugh-wink"></i>
                </div>
                <div class="sidebar-brand-text mx-3">{{entityName}}</div>
            </a>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">

            <!-- Nav Item - Dashboard -->
            <li class="nav-item active">
                <a class="nav-link" href="/">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Tableau de bord</span></a>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">
            <div class="sidebar-heading">
                Dernière mise à jour
            </div>

            <!-- Nav Item - Pages Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link" href="#" >
                    <span>{{ entity ? parseDate(entity.created_at) : '' }}</span>
                </a>
            </li>

        </ul>
        <!-- End of Sidebar -->

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">

                <!-- End of Topbar -->

                <!-- Begin Page Content -->
                <div class="container-fluid pt-4">

                    <!-- Page Heading -->
                    <div class="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 class="h3 mb-0 text-gray-800">Tableau de bord</h1>
                    </div>

                    <!-- Content Row -->
                    <div class="row">

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Revenu (Année)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{ entity ? moneyFormat(entity.hostel.yearIncome) : `- ${devise}`}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Revenue (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Revenu (Mois)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{ entity ? moneyFormat(entity.hostel.monthIncome) : `- ${devise}`}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Earnings (Monthly) Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                                Revenu (Semaine)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{ entity ? moneyFormat(entity.hostel.weekIncome) : `- ${devise}`}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Pending Requests Card Example -->
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                       <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Revenu (Aujourd'hui)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">{{ entity ? moneyFormat(entity.hostel.todayIncome) : `- ${devise}`}}</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Content Row -->

                    <div class="row">

                        <!-- Area Chart -->
                        <div class="col-12">
                            <div class="card shadow mb-4">
                                <!-- Card Header - Dropdown -->
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Evolution</h6>
                                    <div class="dropdown no-arrow">
                                        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                            aria-labelledby="dropdownMenuLink">
                                            <div class="dropdown-header">Dropdown Header:</div>
                                            <a class="dropdown-item" href="#">Action</a>
                                            <a class="dropdown-item" href="#">Another action</a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="#">Something else here</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Card Body -->
                                <div class="card-body">
                                    <div class="chart-area">
                                        <dashboard-chart :type="chartType" :data="chartData" :currency="devise"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <!-- Area Chart -->
                        <div class="col-12">
                            <div class="card shadow mb-4">
                                <!-- Card Header - Dropdown -->
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Chambres</h6>
                                </div>
                                <!-- Card Body -->
                                <div class="card-body">
                                    <div class="row" v-if="roomGroups">
                                        <div class="col-md-6" v-for="group in roomGroups" :key="group._id">
                                            {{ `${group.name} (${group.price} ${devise})` }}
                                            <hr>
                                            <div class="row" v-if="group.rooms.length">
                                                <div class="col-6 room" v-for="room in group.rooms" :key="room._id">
                                                    <span class="btn" :class="room.busy ? 'btn-danger' : 'btn-success'" @click="showRoomInfo(room)">{{ room.name }}</span> <span class="mr-2">{{ room.busy ? 'Occupée' : 'Libre' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- /.container-fluid -->

            </div>
            <!-- End of Main Content -->

            <!-- Footer -->
            <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Hostman live 2022</span>
                    </div>
                </div>
            </footer>
            <!-- End of Footer -->

        </div>
        <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
    </a>

    <!-- Logout Modal-->
    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                    <a class="btn btn-primary" href="login.html">Logout</a>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import DashboardChart from './DashboardChart.vue'
import RoomInfo from './RoomInfo.vue'
import HttpService from '../services/httpService'
import helpers from '@/services/common'
import VModal from 'vue-js-modal'
import _ from 'lodash'
import Vue from 'vue' 
import { ROOM_STATUS } from '@/services/consts.js'
const LOOP_DURATION = 10 /* minutes */ * 60 * 1000; //milliseconds

Vue.use(VModal)

export default {
    name: 'DashBoard',
    data: function () {
        return {
            entityName: 'Signature Hotel',
            entityCode: 'HOST-SIGNATURE-20191122',
            loading: false,
            entity: null,
            devise: 'FCFA',
            chartType: 'bar',
            chartData: null,
            roomStatus: ROOM_STATUS
        }
    },
    methods: {
        getEntityData: async function() {
            this.loading = true
            try {
                const data = await HttpService.get('', { code: this.entityCode })
                if (data.entity) {
                    this.entity = data.entity
                    this.chartData = this.parseChartData(data.entity.hostel.lastMonthsIncome)
                }
            } catch (error) {
                //Nothing to do here
            } finally {
                console.log('Data fetched')
                this.loading = false
            }
        },
        moneyFormat: function(number) {
            return `${helpers.numberFormat(number, 0, ' ')} ${this.devise}`
        },
        parseChartData: (data) => {
            const parsedData = _.transform(data, (result, element) => {
                result.labels.push(element.monthName)
                result.values.push(element.amount)
            }, {labels: [], values: []})
            return parsedData;
        },
        dataUpdateLoop: (instance) => {
            instance.getEntityData()
            setInterval(instance.getEntityData, LOOP_DURATION)
        },
        parseDate : (dateString) => {
            return helpers.parseDateString(dateString)
        },
        showRoomInfo: function (room) {
            this.$modal.show(RoomInfo, { room })
        }
    },
    mounted: function () {
       this.dataUpdateLoop(this)
    },
    computed: {
        roomGroups: function () {
            return this.entity?.hostel?.roomTypes
        }
    },
    components: {
        DashboardChart
    }
}
</script>

<style scoped>
    @import './../assets/styles/sb-admin-2.css';
    .room {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>