<template>
  <div id="app">
    <DashBoard/>
  </div>
</template>

<script>
import DashBoard from './components/DashBoard'

export default {
  name: 'App',
  components: {
    DashBoard
  }
}
</script>

<style>

</style>
