<template>
    <div v-if="room" class='room-content'>
        <div class="title p-2 text-white">
            <h3 class="my-4">Chambre {{ room.name }}</h3>
        </div>
        <div class="body">
        <div class="status-band status-busy" :class="{ 'status-busy': room.busy, 'status-free': !room.busy }"><span>{{ room.busy ? 'OCCUPEE' : 'LIBRE' }}</span></div>
            <div class="content p-2 pl-4" v-if="room.busy">
                <div class="left-band pl-3">
                    <h6><span class="blue-text">Nom du client:</span> {{ room.customer_name }}</h6>
                    <h6><span class="blue-text">Nuitées:</span> {{ room.days }}</h6>
                    <h6><span class="blue-text">Début:</span> {{ parseDate(room.startDate) }}</h6>
                    <h6><span class="blue-text">Fin:</span> <span :class="endPassed() ? 'text-danger' : ''">{{ parseDate(room.endDate) }}</span></h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import helpers from '@/services/common'

export default {
    name: 'RoomInfo',
    props: {
        room: Object
    },
    methods: {
        parseDate : (dateString) => {
            return helpers.parseDateString(dateString)
        },
        endPassed : function() {
            if (!this.room?.endDate) return false
            const now = new Date()
            const endDate = new Date(this.room?.endDate)
            return endDate <= now ? true : false
        } 
    }
}
</script>

<style scoped>
    .room-content .title {
        background-color: #2980b9;
    }
    .blue-text {
        color: #2980b9;
    }
    .status-band {
        height: 35px;
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .status-busy {
        background-color: #e74c3c;
    }
    .status-free {
        background-color: #27ae60;
    }
    .left-band {
        border-left: #2980b9 solid 5px;
    }
</style>