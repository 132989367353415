<template>
    <canvas :id="elementId"></canvas>
</template>

<script>
import { Chart } from 'chart.js'
import { v4 as uuidv4 } from 'uuid';
import helpers from '@/services/common'

const Colors = ['#4e73df','#f1c40f', '#1cc88a', '#8e44ad', '#36b9cc', '#e67e22', '#2e59d9', '#17a673', '#2c9faf', '#e74c3c', '#2c3e50']
const defaultHoverColor = '#17a673'
const defaultHoverBorderColor = 'rgba(234, 236, 244, 1)'

export default {
    name: 'DashboardChart',
    props: {
        type: String,
        data: Object,
        parsing: Object,
        currency: String
    },
    data: function () {
        return {
            elementId: uuidv4(),
            chart: null
        }
    },
    methods: {
        moneyFormat: function(number) {
            return `${helpers.numberFormat(number, 0, ' ')} ${this.currency ?? 'FCFA'}`
        },
        generateColors: function(data) {
            if (data && data.values?.length) {
                let currentColor
                const colors = []
                for (let i = 0; i < data.values.length; i++) {
                    currentColor = Colors[this.getRndInteger(0, Colors.length - 1)]
                    colors.push(currentColor)
                }
                return colors
            } else {
                return Colors[0]
            }
        },
        getRndInteger: function (min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        },
        renderChart: function () {
            const ctx = document.getElementById(this.elementId)
            this.chart = new Chart(ctx, this.buildChartData(this.data))
        },
        buildChartData (data) {
            const instance = this
            return {
                    type: this.type ?? 'bar',
                    data: {
                        labels: data?.labels,
                        datasets: [{
                            data: data?.values,
                            backgroundColor: this.generateColors(data),
                            hoverBackgroundColor: defaultHoverColor,
                            hoverBorderColor: defaultHoverBorderColor,
                            lineTension: 0.2,
                            pointRadius: 3,
                            pointHoverRadius: 3,
                        }],
                    },
                    options: {
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        cutoutPercentage: 80,
                        tooltips: {
                            backgroundColor: "rgb(255,255,255)",
                            bodyFontColor: "#858796",
                            titleMarginBottom: 10,
                            titleFontColor: '#6e707e',
                            titleFontSize: 14,
                            borderColor: '#dddfeb',
                            borderWidth: 1,
                            xPadding: 15,
                            yPadding: 15,
                            displayColors: false,
                            intersect: false,
                            mode: 'index',
                            caretPadding: 10,
                            callbacks: {
                                label: function(tooltipItem, chart) {
                                var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                                return datasetLabel + '\n' + instance.moneyFormat(tooltipItem.yLabel);
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                time: {
                                unit: 'date'
                                },
                                gridLines: {
                                display: false,
                                drawBorder: false
                                },
                                ticks: {
                                maxTicksLimit: 7
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                maxTicksLimit: 5,
                                padding: 10,
                                callback: function(value) {
                                    return instance.moneyFormat(value);
                                }
                                },
                                gridLines: {
                                color: "rgb(234, 236, 244)",
                                zeroLineColor: "rgb(234, 236, 244)",
                                drawBorder: false,
                                borderDash: [2],
                                zeroLineBorderDash: [2]
                                }
                            }],
                        }
                    }
            }
        }
    },
    mounted: function () {
        this.renderChart()
    },
    watch: {
        data() {
            this.renderChart()
        }
    }
}
</script>
