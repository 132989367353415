'use strict'
const numberFormat = (n, decPlaces, thouSeparator, decSeparator) => {
    let i, j, sign
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
    decSeparator = decSeparator == undefined ? "." : decSeparator,
    thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
    sign = n < 0 ? "-" : "",
    i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
}
const parseDateString = (dateString) => {
    //remove the Z to ignore timezone data
    if (dateString.endsWith('Z')) {
        dateString = dateString.slice(0, -1)
    }
    return (new Date(dateString)).toLocaleString('fr-FR', { timeZone: 'Africa/Douala' })
}
export default {
    numberFormat,
    parseDateString
}